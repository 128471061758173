/******************* Django i18n *******************/

// This is just a check to see whether Django jsi18n has been loaded, to give a
// clear error message rather than (or at least before) a bunch of confusing
// errors later on.
if (!window.django) {
  console.error('window.django not defined - ensure Django\'s jsi18n is loaded before this script')
}

import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Tus from '@uppy/tus';
import axios from 'axios';
import Cookie from 'js-cookie';


console.log('get cookie: ', Cookie.get('trxn_id'))

var errorMessages = {
  100: 'Something went wrong, please try again later',
  102: 'Disk space issue',
  103: 'Invalid Transaction ID',
  104: 'HTTP method not allowed',
  106: 'Invalid transaction status',
  107: 'Transaction Expired',
  200: 'Invalid input parameters',
  202: 'Only a maximum of ' + MAX_ALLOW_FILE + ' files can be uploaded',
  203: ' is invalid',
  204: ' is an invalid domain and not allowed',
  205: userEmail + ' is a duplicate email',
  206: 'Only 1 email is allowed',
  207: 'Only ' + messageMaxLength + ' characters is allowed',
  208: 'Only ' + tcMaxLength + ' characters are allowed',
  209: 'Email address is too long',
  300: 'Something went wrong when deleting file',
  500: 'Something went wrong when downloading file(s)'
}


function init() {
  function formatSizeUnits(bytes){
    var base = 1000;
    if      (bytes >= base ** 4)  { bytes = (bytes / base ** 4).toFixed(2) + ' TB'; }
    else if (bytes >= base ** 3)  { bytes = (bytes / base ** 3).toFixed(2) + ' GB'; }
    else if (bytes >= base ** 2)  { bytes = (bytes / base ** 2).toFixed(2) + ' MB'; }
    else if (bytes >= base)       { bytes = (bytes / base).toFixed(2) + ' KB'; }
    else if (bytes > 1)           { bytes = bytes + ' bytes'; }
    else if (bytes == 1)          { bytes = bytes + ' byte'; }
    else                          { bytes = '0 bytes'; }
    return bytes;
  }

  function showFeedback(feedback) {
    document.querySelector('#popup_msg').innerHTML = feedback;
    document.querySelector('#popup').style.display = 'block';
    document.querySelector('#popup').style.visibility = 'visible';
  }

  function dismissFeedback(feedback) {
    document.querySelector('#popup').style.display = 'none';
    document.querySelector('#popup').style.visibility = 'hidden';
  }

  function showFeedbackAndStartover(feedback) {
    document.querySelector('#popup_msg').innerHTML = '<p>' + feedback + '</p>';
    document.querySelector('#dismiss').addEventListener("click", () => {
      window.location.reload();
    })
    document.querySelector('#popup').style.display = 'block';
    document.querySelector('#popup').style.visibility = 'visible';
  }

  function toggleButtons(on) {
    const buttons = [];
    buttons.push(document.querySelector('#uppyModalOpener'));
    buttons.push(document.querySelector('#close_form_submit'));
    buttons.push(document.querySelector('#delete_file_form_submit'));
    for (let i = 0; i < buttons.length; i++) {
      if (on) {
        buttons[i].disabled = false;
        console.log(buttons[i].id + ' enabled')
      } else {
        buttons[i].disabled = true;
        console.log(buttons[i].id + ' disabled')
      }
    }
  }


  // Get any existing transaction details
  axios.get(fileUploadDomain + '/get_details')
    .then((response) => {
      console.log(response.data)
      let detail = response.data
      if (detail.sender !== '') document.querySelector('#sender').value = detail.sender;
      if (detail.to !== '') document.querySelector('#to').value = detail.to;
      if (detail.message !== '') document.querySelector('#message').value = detail.message;
      if (detail.t_and_c !== '') document.querySelector('#t_and_c').value = detail.t_and_c;

      var trxn_id = Cookie.get('trxn_id')
      if (!(trxn_id === null || trxn_id === '')) {
        getUploadedFiles()
      }
    })
    .catch((error) => {
      console.log(error)
    })

  function getUploadedFiles() {
    axios.get(fileUploadDomain + '/list_files')
      .then((response) => {
        console.log(response.data)
        let data = response.data
        if ('ok' === data.result) {
          show_files(data.msg)
        } else {
          showFeedback(data.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function handleResponseMsg(msg) {
    let msgHtml = '';
    if (typeof msg === 'object') {
      let keys = Object.keys(msg)
      let inputs = {
        'sender': 'Your Email',
        'to': 'Recipient email'
      }
      keys.forEach(item => {
        userEmail = msg[item][0]['param']
        // msgHtml += '<p>' + inputs[item] + ': ' + msg[item][0] + '</p>'
        if (inputs[item]) msgHtml += '<p class="bold">' + inputs[item] + '</p>'
        msgHtml += '<p>' + msg[item][0]['param'] + ' ' + errorMessages[msg[item][0]['code']] + '</p>'
      })
    } else {
      msgHtml = '<p>' + errorMessages[msg] + '</p>';
    }
    return msgHtml
  }

  function removeErrorMsg() {
    document.querySelector('.sender-email').classList.remove('error');
    if (document.querySelector('.recipient-email') != null) document.querySelector('.recipient-email').classList.remove('error');
    document.querySelector('.sender-message').classList.remove('error')
    document.querySelector('.sender-tc').classList.remove('error')
  }


  function submitCloseForm(e) {
    e.preventDefault();
    let error = false;
    removeErrorMsg();
    let oFormElement = e.currentTarget;
    if (oFormElement.sender.value === '') {
      document.querySelector('.sender-email').classList.add('error');
      error = true;
    }
    if (oFormElement.to.value === '') {
      document.querySelector('.recipient-email').classList.add('error');
      error = true;
    }
    if (oFormElement.message.value != '' && oFormElement.message.value.length > messageMaxLength) {
      document.querySelector('.sender-message').classList.add('error')
      error = true
    }
    if (oFormElement.t_and_c.value != '' && oFormElement.t_and_c.value.length > tcMaxLength) {
      document.querySelector('.sender-tc').classList.add('error')
      error = true
    }

    if (error) {
      return false;
    }
    toggleButtons(false);

    let formData = new FormData(oFormElement)
    axios.post(oFormElement.getAttribute('action'), formData)
      .then((response) => {
        console.log(response)
        let data = response.data
        if ('ok' === data.result) {
          document.querySelector('#sender').value = ''
          document.querySelector('#to').value = ''
          document.querySelector('#message').value = ''
          document.querySelector('#t_and_c').value = ''
          showFeedbackAndStartover(JSON.stringify(data.msg))
        } else {
          toggleButtons(true)
          if (data.result == 100) {
            showFeedback(handleResponseMsg(data.result))
          } else {
            showFeedback(handleResponseMsg(data.msg))
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })

  }

  function submitDeleteForm(e) {
    e.preventDefault()
    let oFormElement = e.currentTarget
    let formData = new FormData(oFormElement)
    axios.post(oFormElement.getAttribute('action'), formData)
      .then((response) => {
        console.log(response)
        let data = response.data
        if ('ok' === data.result) {
          show_files(data.msg)
        } else if ('300' === data.result) {
          showFeedback(data.result)
          show_files(data.msg)
        } else {
          showFeedback(data.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function refreshFileList() {
    axios.get(fileUploadDomain + '/list_files')
      .then((response) => {
        console.log('refresh list', response)
        let data = response.data
        if ('ok' === data.result) {
          show_files(data.msg)
        } else {
          showFeedback(data.msg)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function show_files(file_json) {
    const filesSectionItems = document.querySelector('.files-section-items');

    filesSectionItems.innerHTML = '';

    file_json.forEach(file => {
      let fileItem = document.createElement('div');
      fileItem.classList.add('files-section-item');
      fileItem.innerHTML = '<div><input name="delete_file" type="checkbox" value="' + file.fid + '"></div><div>' + file.name.substring(0, 20) + '</div><div>' + formatSizeUnits(file.size) + '</div>';
      filesSectionItems.appendChild(fileItem);
    });

    if (file_json.length > 0) {
      document.querySelector('.files-section').classList.add('show');
      document.querySelector('.files-delete-btn').classList.add('show');
      document.querySelector('#close_form_submit').removeAttribute('disabled');
    } else {
      document.querySelector('.files-section').classList.remove('show');
      document.querySelector('.files-delete-btn').classList.remove('show');
      document.querySelector('#close_form_submit').setAttribute('disabled', 'disabled');
    }

    // update counter
    opener = document.querySelector('#uppyModalOpener')
    opener.innerHTML = 'Upload files' + ' (' + file_json.length + ' / ' + MAX_ALLOW_FILE + ')';
    console.log('showfiles')
  }


  const uppy = new Uppy(
    {debug: true,
    autoProceed: false,
    meta: {tid: Cookie.get('trxn_id')},   // for some reason after merge the cookie is not available by the time uppy is created, thus need to add again
    onBeforeFileAdded: (file, files)  => {       // file refers to current file; files refers to those added right before current file
        var added_right_before = Object.keys(files).length
        //var uploaded = document.getElementById('file_table').rows.length - 1  // exclude the header row
        var uploaded = document.querySelectorAll('.files-section-item').length
        var total_file = 1 + added_right_before + uploaded
        if (total_file > MAX_ALLOW_FILE) {
          uppy.info(EMSG_MAX_ALLOW_FILE, 'info', 5000);
          return false;
        }

        return true;
      },
    })
  uppy.use(Dashboard, {trigger: '#uppyModalOpener',
          disableThumbnailGenerator: true,
          showProgressDetails: true, // show numbers in dashboard's status bar
          //inline: true,
          //width: 400,
          //height: 200,
          showSelectedFiles: true,  //Show the list of added files with a preview and file information
          closeModalOnClickOutside: true,
          singleFileFullScreen: false,
          proudlyDisplayPoweredByUppy: false
        })
  uppy.use(Tus, {endpoint: fileUploadDomain + '/upload/', chunkSize: 26214400});
  uppy.on('complete', (fileCount) => { refreshFileList()});

  document.querySelector('#close_form').addEventListener('submit', submitCloseForm)
  document.querySelector('#delete_file_form').addEventListener('submit', submitDeleteForm)
  document.querySelector('#dismiss').addEventListener('click', dismissFeedback)
  document.querySelector('#uppyModalOpener').addEventListener('click', function() { window.uppy.setMeta({tid: Cookie.get('trxn_id') } )})

  if (frameSite === 'arthropod') {
    document.querySelector('#sender').value = userEmail
    document.querySelector('#sender').readOnly = true
  }

  document.querySelector('.delete-all').addEventListener('click', () => {
    let popup = document.createElement('div')
    popup.innerHTML = '<p>Are you sure you want to delete all images?</p><p><button class="confirm-delete-all" type="button">Delete All</button> <button class="cancel-delete-all" type="button">Cancel</button></p>'
    popup.classList.add('confirm-delete-popup')
    document.querySelector('body').appendChild(popup)
    document.querySelector('.cancel-delete-all').addEventListener('click', () => {
      document.querySelector('body').removeChild(popup)
    })
    document.querySelector('.confirm-delete-all').addEventListener('click', () => {
      document.querySelectorAll('.files-section-item input').forEach(input => {
        input.checked = true
      })
      document.querySelector('#delete_file_form_submit').click()
      document.querySelector('body').removeChild(popup)
    })
  })
}

if (document.querySelector('#uppyModalOpener')) {
  init()
}

function download() {
  if (document.getElementById("accept_t_and_c")) {
    document.getElementById("accept_t_and_c").addEventListener("click", accept_tc_yes)
    document.getElementById("not_accept_t_and_c").addEventListener("click", accept_tc_no)
    document.getElementById('print-preview').addEventListener('click', () => {
        window.print();
    });
  }

  function formatSizeUnits(bytes){
    var base = 1000;
    if      (bytes >= base ** 4)  { bytes = (bytes / base ** 4).toFixed(2) + " TB"; }
    else if (bytes >= base ** 3)  { bytes = (bytes / base ** 3).toFixed(2) + " GB"; }
    else if (bytes >= base ** 2)  { bytes = (bytes / base ** 2).toFixed(2) + " MB"; }
    else if (bytes >= base)       { bytes = (bytes / base).toFixed(2) + " KB"; }
    else if (bytes > 1)           { bytes = bytes + " bytes"; }
    else if (bytes == 1)          { bytes = bytes + " byte"; }
    else                          { bytes = "0 bytes"; }
    return bytes;
  }

  function refreshSize() {
    let sizeItems = document.querySelectorAll('.file-size')
    sizeItems.forEach(item => {
      item.innerHTML = formatSizeUnits(item.innerHTML)
    })
  }

  function showExpiryMessage() {
    console.log('expiry message')
      if (is_files_done) {
          document.getElementById("expiry_msg").innerHTML = expiry_msg1
      } else {
          document.getElementById("expiry_msg").innerHTML = expiry_msg2
      }
  }

  function markCleanUp() {
      if (is_files_done) {
          return;
      }

      var xmlhttp1 = new XMLHttpRequest();
      xmlhttp1.responseType = 'json'
      xmlhttp1.open('GET', '/clean_up_trxn/'+ accessKey + '/' + userEmail, true);
      xmlhttp1.send()
      xmlhttp1.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
              console.log(this.response)
              if ("ok" === this.response.result) {
                  is_files_done = true;
                  showExpiryMessage()
              } else {
                  if (document.getElementById("feedback") != null)document.getElementById("feedback").innerHTML = this.response.msg;
              }
          }
      }
  }

  function showActivity() {
      var xmlhttp1 = new XMLHttpRequest();
      xmlhttp1.responseType = 'json'
      xmlhttp1.open('GET', '/show_activity/'+ accessKey + '/' + userEmail, true);
      xmlhttp1.send()
      xmlhttp1.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
              console.log(this.response)
              if ("ok" === this.response.result) {
                  showActivityTable(this.response.msg)
              } else {
                if (document.getElementById("feedback") != null) document.getElementById("feedback").innerHTML = this.response.msg;
              }
          }
      }
  }

  function showActivityTable(file_json) {
      const activityItems = document.querySelector('.activity-items');
      activityItems.innerHTML = '';
      file_json.forEach(item => {
          let activityItem = document.createElement('div');
          activityItem.innerHTML = '<div>' + item.date + '</div><div>' + item.ip + '</div><div>' + item.message + '</div>';
          activityItem.classList.add('activity-item');
          activityItems.appendChild(activityItem);
      });
      // if (file_json.length > 0) document.querySelector('.activity-log').classList.add('show');
  }

  function download_file(event) {
      var xmlhttp1 = new XMLHttpRequest();
      xmlhttp1.responseType = 'json'
      xmlhttp1.open('GET', '/download/'+ accessKey + '/' + userEmail, true);
      xmlhttp1.send()
      xmlhttp1.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
              console.log(this.response)
              if ("ok" === this.response.result) {
                  showActivityTable(this.response.msg)
              } else {
                if (document.getElementById("feedback") != null) document.getElementById("feedback").innerHTML = this.response.msg;
              }
          }
      }
  }

  function accept_tc_yes() {
    accept_tc('T', '/en/download_page?access_key='+ accessKey + '&email=' + userEmail + '&site=' + frameSite)
  }

  function accept_tc_no() {
    accept_tc('F', "https://protectchildren.ca/en/")
  }

  function accept_tc(answer, goto) {
    var xmlhttp1 = new XMLHttpRequest();
    xmlhttp1.responseType = 'json'
    console.log(accessKey, userEmail)
    xmlhttp1.open('GET', '/accept_t_and_c/'+ accessKey + '/' + userEmail + '/' + answer, true);
    xmlhttp1.send();
    xmlhttp1.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
            console.log(this.response)
            if ("ok" === this.response.result) {
                window.location.href = goto;
            } else {
                console.log("something wrong")
                console.log(this.status, errorMessages[this.status])
                if (document.getElementById("feedback") != null) document.getElementById("feedback").innerHTML = this.response.msg;
            }
        }
    }
  }

  const csrftoken = Cookie.get('csrftoken')
  function accept_tc_yes() {
      accept_tc('1', '/en/download_page?access_key='+ accessKey + '&email=' + userEmail + '&site=' + frameSite)
  }
  function accept_tc_no() {
      accept_tc('0', "https://protectchildren.ca/en/")
  }
  function accept_tc(answer, goto) {
      var xmlhttp1 = new XMLHttpRequest();
      xmlhttp1.responseType = 'json'
      xmlhttp1.open("POST", '/accept_t_and_c/'+ accessKey + '/' + userEmail + '/' + answer, true);
      xmlhttp1.setRequestHeader("X-CSRFToken", csrftoken);
      xmlhttp1.send();
      xmlhttp1.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
              console.log(this.response)
              if ("ok" === this.response.result) {
                  window.location.href = goto;
              } else {
                  console.log("something wrong")
                  document.getElementById("feedback").innerHTML = this.response.msg;
              }
          }
      }
  }

  refreshSize()
  if (is_files_done !== null) showExpiryMessage()
  if (document.querySelector('.activity-items') != null) showActivity()
  if (document.getElementById("mark_clean_up_button") != null) document.getElementById("mark_clean_up_button").addEventListener("click", markCleanUp)
  if (document.getElementById("refresh_activity") != null) document.getElementById("refresh_activity").addEventListener("click", showActivity)

  let files = document.querySelectorAll('.download-files-item')

  function downloadFile(i) {
      if (i < files.length) {
          const response = fetch(files[i].querySelector('a').href)
              .then((response) => response.blob())
              .then((blob) => {
                  const objectURL = URL.createObjectURL(blob)
                  const a = document.createElement('a')
                  a.href = objectURL
                  a.download = files[i].querySelector('a').dataset.filename
                  document.body.appendChild(a)
                  a.click()
                  downloadFile(i + 1)
              })
      } else if (i >= files.length) {
          downloadAll.disabled = false
          downloadPopup.style.display = 'none'
      }
  }

  function downloadAllFiles() {
      downloadFile(0)
      downloadAll.disabled = true
      downloadPopup.style.display = 'block'
  }
  
  const downloadAll = document.querySelector('.download-all')
  const downloadPopup = document.querySelector('.download-popup')

  if (downloadAll != null) downloadAll.addEventListener('click', downloadAllFiles)

  if (document.querySelector('.download-error-message') != null) {
    var errorMessage = document.querySelector('.download-error-message')
    console.log(errorMessage.dataset.error, errorMessages[errorMessage.dataset.error])
    errorMessage.innerHTML = errorMessages[errorMessage.dataset.error]
  }

  if (document.querySelector('.download-close')) {
    document.querySelector('.download-close').addEventListener('click', () => {
      window.close()
    })
  }

}
if (document.querySelector('.download-section')) {
  download()
}
